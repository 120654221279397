import React from 'react';

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import screenshot from "./assets/images/legalist-screenshot.png";
import logo from "./assets/images/legalist-logo.png";

import Facebook from "./assets/images/Facebook.png";
import Twitter from "./assets/images/Twitter.png";
import Instagram from "./assets/images/Instagram.png";
import IphoneMockup from "./assets/images/legalist-iphone-mockup.png";

import One from "./assets/images/one.png";
import Two from "./assets/images/two.png";
import Three from "./assets/images/three.png";


function App() {
  const h2 = "Legalist ile sözleşmeniz dakikalar içerisinde hazır.";
  const par1 = "İhtiyacınız olan sözleşmeyi Legalist’in sözleşme üretim platformunu kullanarak hazırlayabilir, kendinizi kolayca koruma altına alabilirsiniz. Legalist ile sözleşme hazırlamak hem hızlı hem de kolay.";
  const feat1 = "Hangi sözleşmenin nasıl bir şekilde dizayn edilmesi gerektiğini belirlemek için ihtiyaçlarınıza yönelik tercihleri belirleyin.";
  const feat2 = "Sözleşmede yer almasını istediğiniz bilgileri girerek sizin için özelleştirilmiş sözleşmenize son halini verin.";
  const feat3 = "Tercihleri belirleyip bilgileri girdiğiniz sözleşmenizi dilediğiniz formatta indirin, çıktısını alıp imzalayın.";
  const third1 = "İster evinizi kiralayın, ister yeni ortağınızla ilişkilerinizi düzenleyin, ya da freelancer olarak birisine hizmet sağlayın. Legalist’i kullanarak tüm hukuki ihtiyaçlarınıza yönelik sözleşmeleri hazırlayabilirsiniz.";
  const third2 = "Sözleşmelerinizi Legalist sistemi üzerinden imzaya hazır hale getirebilirsiniz. 15dk içerisinde koruma altındasınız.";
  const third3 = "Tercihlerinizi netleştiremediniz mi? Ziyanı yok, hesabınıza istediğiniz yerden 7/24 ulaşabilir, dilediğiniz zaman sözleşmenizi tamamlayabilirsiniz.";
  const forth = "Mail listemize kaydolarak, hizmetlerimizden ve son gelişmelerden haberdar olun.";
  return(
    <div>

      <div className="navigation" >
        <Container fluid="true">
          {/* Stack the columns on mobile by making one full-width and the other half-width */}
          <Row>
            <Col xs={4} md={4}>
                  <img 
                    width={214}
                    height={56}
                    className="mr-3"
                    alt="Legalist Ekran Görüntüsü"
                    src={logo}/>
            </Col>
            <Col xs={4} md={4}></Col>
            <Col xs={4} md={4}><a href="#forth2"><Button style={{float: "right"}}>Hemen Başla</Button></a></Col>
          </Row>
        </Container>
      </div>

      <div className="first" >
        <Container fluid="true">
        {/* Stack the columns on mobile by making one full-width and the other half-width */}
          <Row>
            <Col xs={12} md={2}></Col>
            <Col xs={12} md={6} style={{textAlign:"center"}}>
              <div className="covertext">
              <h1>{h2}</h1>
              <p>{par1}</p>
              <a href="#forth2"><Button>Hemen Başla</Button></a>
              </div>
            </Col>
            <Col xs={12} md={2}>
              <img 
                width={"100%"}
                height={"auto"}
                alt="Legalist Iphone Mockup"
                src={IphoneMockup}/>
            </Col>
            <Col xs={12} md={2}></Col>

          </Row>
        </Container>
      </div>

    <div className="second" >
      <Container fluid="true">
      {/* Stack the columns on mobile by making one full-width and the other half-width */}
        <Row>
          <Col xs={12} md={4} >
          <p style={{textAlign:"center"}}><img
                width={"33%"}
                height={"auto"}
             
                alt="Number One"
                src={One}/></p>
            <Row>
              <Col xs={1} md={2} xl={1}><i className="material-icons">view_carousel</i></Col>
              <Col xs={11} md={10} xl={11}><h5>Tercihlerinizi Belirleyin</h5></Col>
            </Row>
              <p>{feat1}</p>
          </Col>

          <Col xs={12} md={4}>
          <p style={{textAlign:"center"}}><img
                width={"33%"}
                height={"auto"}
              
                alt="Number Two"
                src={Two}/></p>
            <Row>
              <Col xs={1} md={2} xl={1}><i className="material-icons">create</i></Col>
              <Col xs={11} md={10} xl={11}><h5>Bilgilerinizi Girin</h5></Col>
            </Row>
              <p>{feat2}</p>
          </Col>

          <Col xs={12} md={4}>
          <p style={{textAlign:"center"}}><img
                width={"33%"}
                height={"auto"}
          
                alt="Number Three"
                src={Three}/></p>
            <Row>
              <Col xs={1} md={2} xl={1}><i className="material-icons">save_alt</i></Col>
              <Col xs={11} md={10} xl={11}><h5>İndirin, Bastırın ve İmzalayın</h5></Col>
            </Row>
              <p>{feat3}</p>
          </Col>
        </Row>
      </Container>
    </div>


    <div className="third" >
      <Container fluid="true">
      {/* Stack the columns on mobile by making one full-width and the other half-width */}
        <Row>

          <Col xs={12} md={6} xl={4}>
            <img 
              width={"100%"}
              height={"auto"}
              className="mr-3"
              alt="Legalist Ekran Görüntüsü"
              src={screenshot}/>
          </Col>

          <Col xs={12} md={6} xl={8}>
            <h2>İhtiyacınıza yönelik tüm sözleşmeler</h2>
            <p>{third1}</p>
            <Row>
              <Col xs={12} md={5}>
              <h5>Tam Otomasyon</h5>
              <p>{third2}</p>
              </Col>
              <Col xs={12} md={7}>
              <h5>Her Yerden Erişim</h5>
              <p>{third3}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>

    <div className="forth" id="forth2" >
        <Container fluid="true">
        {/* Stack the columns on mobile by making one full-width and the other half-width */}
          <Row>
            <Col xs={12} md={3}></Col>
            <Col xs={12} md={6} style={{textAlign:"center"}}>
              <h6>Ailemize katılın</h6>
              <h2>{forth}</h2>
                  
                  <div id="mc_embed_signup">
                    <Form 
                      action="https://legalist.us4.list-manage.com/subscribe/post?u=bf0e9524ea3d765ba10131675&amp;id=9d95782c19"
                      // action="https://legalist.net"
                      method="post" 
                      name="mc-embedded-subscribe-form"
                      className="validate" 
                      target="_blank" 
                      novalidate>
                        <Row>
                          <Col xs={2} md={1}></Col>
                          <Col xs={10} md={9}  style={{padding: "0 0 0 0", marginLeft: "-45px"}}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control type="email" name="EMAIL" placeholder="Mail adresiniz" required />
                            </Form.Group>
                                {/* <!-- Bot Signup Protection--> */}
                            <Form.Group style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                                <Form.Control type="text" name="b_bf0e9524ea3d765ba10131675_9d95782c19" tabindex="-1" value="" />
                            </Form.Group>

                            <Form.Group controlId="formBasicCheckbox">
                              <Form.Check style={{textAlign: "left"}} type="checkbox" label="Mail adresinizi paylaşarak, Kullanım Koşulları ve Gizlilik Politikasını kabul etmiş sayılırsınız." required />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={1}><Button type="submit" value="Kaydol" name="subscribe" style={{padding: "7px 70px 7px 70px"}}>Kaydol</Button></Col>
                          <Col xs={12} md={1}></Col>
                        </Row>
                    </Form>
                  </div>

            </Col>
            <Col xs={12} md={3}></Col>

          </Row>
        </Container>
      </div>

      <div className="fifth" >
      <Container fluid="true">
      {/* Stack the columns on mobile by making one full-width and the other half-width */}
        <Row>
          <Col xs={12} md={4}>
              <img 
                width={214}
                height={56}
                className="mr-3"
                alt="Legalist Ekran Görüntüsü"
                src={logo}/>
              <p>Mail listemize kaydolarak ürün ve hizmetlerimize ilişkin son gelişmelerden haberdar olun.</p>
              <div id="mc_embed_signup" style={{backgroundColor: "transparent"}}>
                    <Form 
                      action="https://legalist.us4.list-manage.com/subscribe/post?u=bf0e9524ea3d765ba10131675&amp;id=9d95782c19"
                      // action="https://legalist.net"
                      method="post" 
                      name="mc-embedded-subscribe-form"
                      className="validate" 
                      target="_blank" 
                      novalidate>
                        <Row>
                          <Col xs={1} md={1}></Col>
                          <Col xs={11} md={9}  style={{padding: "0 0 0 0", marginLeft: "-40px"}}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control type="email" name="EMAIL" placeholder="Mail adresiniz" required />
                            </Form.Group>
                                {/* <!-- Bot Signup Protection--> */}
                            <Form.Group style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                                <Form.Control type="text" name="b_bf0e9524ea3d765ba10131675_9d95782c19" tabindex="-1" value="" />
                            </Form.Group>

                            <Form.Group controlId="formBasicCheckbox">
                              <Form.Check style={{textAlign: "left"}} type="checkbox" label="Mail adresinizi paylaşarak, Kullanım Koşulları ve Gizlilik Politikasını kabul etmiş sayılırsınız." required />
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={1}><Button type="submit" value="Kaydol" name="subscribe" style={{padding: "7px 20px 7px 20px"}}>Kaydol</Button></Col>
                          <Col xs={12} md={1}></Col>
                        </Row>
                    </Form>
                  </div>
          </Col>
          <Col xs={12} md={4}>

          </Col>
          <Col xs={12} md={4} style={{textAlign: "center", paddingTop: "50px"}}>
            <Row>
              <Col xs={12} md={12} lg={12} xl={4}>
              <a href="https://www.facebook.com/legalistnet/"><nobr><img width={24} height={24} className="mr-3" alt="Facebook" src={Facebook}/> Facebook</nobr></a>
                </Col>
              <Col xs={12} md={12} lg={12} xl={4}>
              <a href="https://twitter.com/LegalistNet"><nobr><img width={24} height={24} className="mr-3" alt="Twitter" src={Twitter}/> Twitter</nobr></a>
              </Col>
              <Col xs={12} md={12} lg={12} xl={4}>
              <a href="https://www.instagram.com/legalistnet/"><nobr><img width={24} height={24} className="mr-3" alt="Instagram" src={Instagram}/> Instagram</nobr></a>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}><a href="#">Gizlilik Politikası</a></Col>
              <Col xs={12} md={6}><a href="#">Kullanım Koşulları</a></Col>
            </Row>
            <p>© 2020 Legalist. Tüm hakları saklıdır.</p>
          </Col>
        </Row>
      </Container>
    </div>


    </div>
  );
}  

export default App;


